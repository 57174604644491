export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [];

export const dictionary = {
		"/(public)": [12,[4]],
		"/(private)/documents": [5,[2,3]],
		"/(private)/documents/[documentId]": [6,[2,3]],
		"/(private)/documents/[documentId]/rapports/[rapportId]": [7,[2,3]],
		"/(private)/documents/[documentId]/rapports/[rapportId]/position/[positionId]": [8,[2,3]],
		"/(private)/documents/[documentId]/rapports/[rapportId]/position/[positionId]/extent/[extentId]": [9,[2,3]],
		"/(private)/documents/[documentId]/settings": [10,[2,3]],
		"/(public)/forgot-login-names": [13,[4]],
		"/(public)/forgot-password": [14,[4]],
		"/(public)/login": [15,[4]],
		"/(private)/mobile-settings": [11,[2]],
		"/(public)/noauth": [16,[4]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';